import { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { CabButton, CabIcon } from '@CabComponents';
import { IoCreateOutline, IoAdd } from 'react-icons/io5';

interface ComponentProps {
  title: string;
  addCard?: () => void;
  editCategory?: () => void;
}

type Props = ComponentProps;

export const CardsPageHeader = ({ title, addCard, editCategory }: Props): ReactElement => {
  return (
    <Box display='flex' width='100%' justifyContent='space-between'>
      <Typography variant="h1">{title}</Typography>
      <Box display='flex' gap={2}>
        {editCategory &&
            <CabButton buttonType='tertiary' onClick={editCategory} 
              icon={<CabIcon alt='Edit' Icon={IoCreateOutline}/>}
            >
              Edit
            </CabButton>
        }
        {addCard &&
            <CabButton onClick={addCard} 
              icon={<CabIcon alt='Edit' Icon={IoAdd}/>}
            >
              Add
            </CabButton>
        }
      </Box>
    </Box>
  );
};

export default CardsPageHeader;