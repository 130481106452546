import { ReactElement } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { CabIcon } from '@CabComponents/CabIcon';
import { IoStar } from 'react-icons/io5';


interface Copy {
  title: string;
  icon: string;
  icon_url: string;
  listCaption: string;
  info: string[];
}

interface ComponentProps {
  copy: Copy
}

export const NoteOnboarding = ({ copy }: ComponentProps): ReactElement => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body1" marginTop={3} marginBottom={2}>
        {copy.listCaption}
      </Typography>
      <List dense>
        {copy.info.map((infoMessage, index) => (
          <ListItem disableGutters key={index}>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <CabIcon Icon={IoStar} />
            </ListItemIcon>
            <ListItemText primary={infoMessage} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default NoteOnboarding;
